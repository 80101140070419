import React from "react";
import { connect } from "react-redux";
import { firebase, getFullSiteData, getTeacherData } from "~/src/api";

import {
  HomePage as Home,
  SitePage,
  TeacherPage,
} from "~/src/components/pages";

const ROOT_DOMAIN = "bolstr";

const HomePage = (props) => (
  props.site ? (
    <SitePage {...props} />
  ) : props.teacher ? (
    <TeacherPage
      authUser={props.authUser}
      teacher={props.teacher}
    />
  ) : (
    <Home {...props} />
  )
);

HomePage.getInitialProps = async function ({ req }) {
  let domain = req ? req.headers.host : window.location.host;
  [domain] = domain.split(":"); // Remove :3000 locally
  const [subdomain] = domain.split(".");

  if (subdomain !== ROOT_DOMAIN) {
    // First check if there's a site on this domain
    const site = await firebase.sites().where("domain", "==", domain).get();
    if (site.docs && site.docs.length) {
      return {
        ...await getFullSiteData(site.docs[0]),
      };
    }

    // If not, check if there's a teacher with this subdomain as username
    const teacher = await firebase.teachers().where("username", "==", subdomain).get();
    if (teacher.docs && teacher.docs.length) {
      return {
        teacher: await getTeacherData({
          teacher: teacher.docs[0],
          withExperiences: true,
          withQualifications: true,
        }),
      };
    }
  }

  // If not, return the HomePage
  return {};
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(HomePage);
